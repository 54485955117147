import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/castellorosso.png'
import strory2 from '../../images/events/2.jpg'
import strory3 from '../../images/events/3.jpg'
import francesco from '../../images/events/fra.png'

import './style.css'

const Location = () => {
    return(

        
    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'Dove & Quando'}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Cerimonia</h3>
                                <span>Venerdì 21 Luglio 2023 ore 15:30</span>
                                <span>Castello Rosso</span>
                                <span>via Ammiraglio Reynaudi, 5, 12024 Costigliole Saluzzo CN, Italia</span>
                                <a target={"_blank"} style={{textDecoration:'underline'}}href="https://www.google.com/maps/place/Hotel+Castello+Rosso/@44.5636062,7.4794435,17z/data=!3m1!4b1!4m9!3m8!1s0x12cd46fef3caf45d:0x967796bcadbdb301!5m2!4m1!1i2!8m2!3d44.5636062!4d7.4816322!16s%2Fg%2F11c54c_gjf">Indicazioni stradali</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="ceromony-content ceromony-content2">
                            <h3>Festeggiamenti</h3>
                          {/*   <span>Sunday, 25 July 18, 9.00 AM-5.00 PM</span>
                            <span>256 Apay Road,Califonia Bong, London</span> */}
                            <p>Siamo felici di condividere con voi i dettagli del nostro grande giorno, dopo una cena abbondante seguirà festaccia con open bar e DJ, date libero sfogo a tutte le vostre richieste e soprattutto, siate creativi!! 
Inoltre, abbiamo lasciato l’organizzazione del divertimento a Francesco per cui non sappiamo bene cosa ci/vi aspetterà, abbiate una mente aperta….

</p>
                    {/*         <Link to="/">See Location</Link> */}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                            <img src={francesco} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        {/*     <div className="Ceremony-wrap">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="ceromony-img">
                             <img src={strory3} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="ceromony-content">
                            <h3>Reception Party</h3>
                            <span>Sunday, 25 July 18, 9.00 AM-5.00 PM</span>
                            <span>256 Apay Road,Califonia Bong, London</span>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p>
                            <Link to="/">See Location</Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
    )
}
export default Location;