import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.css';

export default class MobileMenu extends Component {
	state = {
		isMenuShow: false,
		isOpen: false
	};

	menuHandler = () => {
		this.setState({
			isMenuShow: !this.state.isMenuShow
		});
	};

	setIsOpen = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	render() {
		const { isMenuShow, isOpen } = this.state;

		return (
			<div>
				<div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
					{/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}
					<div className="logo2">
						<h2>
							<Link to="/home">Giacomo & Martina</Link>
						</h2>
					</div>
					<ul className="responsivemenu">
						<Card>
							<CardBody>
					
								<li>
									<AnchorLink href="#couple">Sposi</AnchorLink>
								</li>
								<li>
									<AnchorLink href="#story">La nostra storia</AnchorLink>
								</li>
								<li>
									<AnchorLink href="#people">Testimoni and Co</AnchorLink>
								</li>
								<li>
									<AnchorLink href="#event">Evento</AnchorLink>
								</li>
								<li>
										<AnchorLink href="#gallery">Foto</AnchorLink>
									</li>
							</CardBody>
						</Card>
					</ul>
				</div>

				<div className="showmenu" onClick={this.menuHandler}>
					<i className="fa fa-bars" aria-hidden="true" />
				</div>
			</div>
		);
	}
}
