import React, { useState, Component, useEffect, useCallback } from 'react';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import Sectiontitle from '../section-title';
import './style.css';

import galimg1 from '../../images/gal/1.jpg';
import galimg2 from '../../images/gal/6.jpg';
import galimg3 from '../../images/gal/3.jpg';
import galimg4 from '../../images/gal/4.jpg';
import galimg5 from '../../images/gal/5.jpg';
import galimg6 from '../../images/gal/2.jpg';
import { Button } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Carousel, { Modal, ModalGateway } from 'react-images';

function Gallery() {
	const [ photos, setPhotos ] = useState([]);
	const [ currentImage, setCurrentImage ] = useState(0);
	const [ viewerIsOpen, setViewerIsOpen ] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	useEffect(() => {
		const data = [];
		let i = 121;
		while (i > 1) {
			data.push({
				original: "https://21luglio2023.it/foto/JackTinna"+i+".jpg",
				thumbnail:"https://21luglio2023.it/foto/JackTinna"+i+".jpg",
			});
			i = i - 1;
		}

		setPhotos(data);
	}, []);


	const images = [
		{
			original: "https://21luglio2023.it/foto/JackTinna1.jpg",
			thumbnail: "https://21luglio2023.it/foto/JackTinna1.jpg",
		},
		{
			original: "https://21luglio2023.it/foto/JackTinna2.jpg",
			thumbnail: "https://21luglio2023.it/foto/JackTinna2.jpg",
		},
		{
			original: "https://21luglio2023.it/foto/JackTinna3.jpg",
			thumbnail: "https://21luglio2023.it/foto/JackTinna3.jpg",
		},
		{
			original: "https://21luglio2023.it/foto/JackTinna4.jpg",
			thumbnail: "https://21luglio2023.it/foto/JackTinna4.jpg",
		},
		
	];

	/* 	useEffect(() => {
		async function doCall() {
			const data = {
				target: 'instagram_graphql_hashtag',
				url: 'https://www.instagram.com/explore/tags/jacktinnasposi',
				locale: 'it',
				geo: 'Italy'
			};
			axios
				.post('https://scraper-api.smartproxy.com/v1/scrape', data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Basic UzAwMDAxMTE0OTg6UCRXMTgzZjM0YzlhNDE5ODdhYzVlMmEzMDJmMTkwY2Q5MDdk'
					}
				})
				.then((response) => {
					console.log(response);
					setData(response.data.data.content.hashtag.edge_hashtag_to_media.edges);
				})
				.catch((error) => {
					console.log(error);
				});
		}
		doCall();
	}, []); */

	/* 	const imagePath =
		'https://instagram.fcia6-1.fna.fbcdn.net/v/t51.2885-15/361087574_844016856646010_9121222246511234663_n.jpg?stp=dst-jpg_e35&_nc_ht=instagram.fcia6-1.fna.fbcdn.net&_nc_cat=102&_nc_ohc=BC5BJp5sqK4AX9A5DIT&edm=ABZsPhsBAAAA&ccb=7-5&oh=00_AfAZTN1iNfL3_KoTamFRDHAL9XAtmBR0hyaM2h46LmgGFg&oe=64B9B7D6&_nc_sid=e1d802%22';

	function convertImageToBase64(imgUrl, callback) {
		const image = new Image();
		image.crossOrigin = 'anonymous';
		image.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			canvas.height = image.naturalHeight;
			canvas.width = image.naturalWidth;
			ctx.drawImage(image, 0, 0);
			const dataUrl = canvas.toDataURL();
			console.log(dataUrl);
			callback && callback(dataUrl);
		};
		image.src = imgUrl;
	} */

	return (
		<div id="gallery" className="Gallery-section section-padding pb-70">
			<Sectiontitle section={'Le foto del matrimonio'} />
			<div className="container">
			 <ImageGallery items={photos} />
		
				{/* <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%' }}>
					<Button>Vedi tutte le foto</Button>
				</div> */}
			</div>
		</div>
	);
}

export default Gallery;
