import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'


const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Ti sei già un pò emozionato? </h2>
                            <p>Se hai piacere di dire o leggere qualcosa durante la cerimonia, questo è il tuo momento!
                             Vi aspettiamo in numerosi con il microfono in mano e non vergognarti (anche noi saremo emozionatissiiiiimiiiii), faccelo sapere in modo da organizzare la scaletta.
                            <br/>
                            Oppure se hai bisogno prima di qualche bicchierino fresco non mancheranno momenti in cui potrai acchiappare il microfono al nostro dj e far ridere tutti con qualche aneddoto.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;