import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Sectiontitle from "../section-title";
import bride1 from "../../images/groomsmen-bridesmaid/davide.jpg";
import bride2 from "../../images/groomsmen-bridesmaid/luigi.png";
import bride3 from "../../images/groomsmen-bridesmaid/lella.png";
import bride4 from "../../images/groomsmen-bridesmaid/4.jpg";
import bride5 from "../../images/groomsmen-bridesmaid/sharon.jpg";
import bride6 from "../../images/groomsmen-bridesmaid/francesca.jpg";
import bride7 from "../../images/groomsmen-bridesmaid/sonia.jpg";
import bride8 from "../../images/groomsmen-bridesmaid/8.jpg";
import bride9 from "../../images/groomsmen-bridesmaid/9.jpg";
import bride10 from "../../images/groomsmen-bridesmaid/10.jpg";
import sharon from "../../images/groomsmen-bridesmaid/sharon.png";
import balli from "../../images/groomsmen-bridesmaid/balli.png";
import celanza from "../../images/groomsmen-bridesmaid/celanza.png";

import "./style.css";

const People = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div id="people" className="person-area  pb-70">
      <div className="container">
        <Sectiontitle section={"Testimoni"} />
        <div className="person-area-menu" >
          <div className="person-btn">
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:20,flexDirection:'column'}}>
            <span style={{textAlign:'center',marginBottom:20}}>Clicca sui due tasti per visualizzare i nostri testimoni.</span>
       
            <Nav tabs style={{marginBottom:10}}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                 Sposo
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                   Sposa
                </NavLink>
              </NavItem>
            </Nav>
            </div>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="Groomsman-wrap">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride1} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Davide</h3>
                        <span>Testimone</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride2} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Luigi</h3>
                        <span>Testimone</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride3} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Raffaella</h3>
                        <span>Testimone</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="Ceremony-wrap">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={sharon} alt="" />
                        {/*   */}
                      </div>
                      <div className="person-content">
                        <h3>Sharon</h3>
                        <span>Testimone</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={balli} alt="" />
                        {/*   */}
                      </div>
                      <div className="person-content">
                        <h3>Francesca</h3>
                        <span>Testimone</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={celanza} alt="" />
                        {/*  */}
                      </div>
                      <div className="person-content">
                        <h3>Sonia</h3>
                        <span>Testimone</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default People;
