/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import strory1 from "../../images/story/1.jpg";
import strory2 from "../../images/story/2.jpg";
import story5 from "../../images/story/proposta.png";
import strory3 from "../../images/story/3.jpg";
import strory4 from "../../images/story/4.jpg";
import mauritius from '../../images/story/mauritius.png'
import seychelles from '../../images/story/seychelles.png'
import thailandia1 from '../../images/story/thailandia1.png'
import thailandia2 from '../../images/story/thailandia2.png'

import dubai from '../../images/story/dubai.png'
import convivenza from '../../images/story/convivenza.png'
import maldive from '../../images/story/maldive.png'
import incontro from '../../images/story/incontro.png'
import eiffelTower from './eiffel-tower.png'

import gameover from '../../images/story/gameover.png'
import { TbAmbulance,TbBuildingSkyscraper
} from 'react-icons/tb';
import {GiSeaTurtle,GiMonkey,GiDolphin,GiSharkFin,GiRing,GiElephant
} from 'react-icons/gi'
import "./style.css";
import {BiHomeHeart} from 'react-icons/bi'

const Story = () => {
  return (
    <div id="story" className="story-area section-padding">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Qualcosa su di noi</h2>
            <p>
              Sicuramente sarete curiosi di sapere qualche dettaglio piccante
              sulla nostra storia
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="story clearfix">
              <div className="story-top"></div>
              <div className="content-wrapper">
                <div className="item">
                  <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <TbAmbulance size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content" >
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={incontro} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Un incontro casuale</h2>
                    <span className="date">2017</span>
                    <p>
                      Ci siamo incontrati per caso durante un turno del 118,
                      dopo alcuni turni burrascosi la nostra storia ha
                      incominciato a prendere forma condividendo la passione per
                      la "ninonino" e le nottate insonni all'insegna dei mal di
                      pancia dalle 3 del pomeriggio (che ovviamente si
                      intensificano nelle ore notturne), e scivoloni in bagni
                      troppo stretti per muoversi comodamente in due.
                    </p>
                    <div className="story-button"></div>
                  </div>
                </div>
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <GiSeaTurtle size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={seychelles} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Seychelles</h2>
                    <span className="date"> 2017 </span>
                    <p>
                      Ma sono i viaggi da almeno 14 ore, il sole cocente e il
                      mare azzurrissimo dell’oceano indiano che ci hanno
                      traghettato verso nuovi mondi avventurosi.
                      <br /> Seychelles dove i pullman senza porte sfrecciano
                      per strade sgangherate con fermate inesistenti e discese
                      in corsa.
                    </p>
                  </div>
                </div>
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <GiDolphin size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={mauritius} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Mauritius</h2>
                    <span className="date"> 2018 </span>
                    <p>
                      Mauritius paradisiaca solo se non sei già stato alle
                      Seychelles (abbiamo fatto una pessima scelta)
                      caratterizzata da distese di sabbia
                      bianca senza un millimetro di ombra e ananas intagliati in modo pittoresco.
                    </p>
                  </div>
                </div>{" "}
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <GiMonkey size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={thailandia1} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Thailandia</h2>
                    <span className="date"> 2019 </span>
                    <p>
                      Thailandia immersa in un affascinante caos organizzato di
                      Bangkok, meravigliosi templi buddisti in cui per entrare
                      devi essere ben vestito, isole fatte da strade tutte
                      dritte (e meno male perché la svolta a destra si fa da
                      sinistra) e scimmie impertinenti.
                    </p>
                  </div>
                </div>
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <TbBuildingSkyscraper size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={dubai} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Dubai</h2>
                    <span className="date"> 2019 </span>
                    <p>
                      Non dimentichiamoci di Dubai, un parco giochi apprezzato
                      solo da chi guadagna davvero molto ( mooolto bene) dove
                      all’interno di immensi grattacieli e centri commerciali
                      puoi affittare degli sci, salire su una seggiovia e
                      scendere su piste battute sfrecciando accanto ai pinguini
                      (tutto ciò circondati da immense distese di caldissimo
                      deserto).
                      <br />
                      Che abbiamo fatto noi? Booo non lo sappiamo bene neanche
                      noi, ci siamo goduti la nostra jacuzzi sul terrazzo al 40
                      esimo piano.
                    </p>
                  </div>
                </div>
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <BiHomeHeart size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={convivenza} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Convivenza</h2>
                    <span className="date"> 2020 </span>
                    <p>
                      Ma è con La pandemia del 2020 che il meglio inzia a
                      prendere forma : la nostra convivenza!!
                      <br />
                      Spesa, Lavatrici e maglioni ritirati ormai non hanno più
                      segreti, una routine quotidianamente scombinata e confusa
                      da meravigliosi turni ospedalieri e da nottate insonni a
                      schiacciar tasti! Per fortuna a noi ci pensa bambolino
                      nero che con le sue immancabili fusa ci ricorda che anche
                      lui deve mangiare.
                    </p>
                  </div>
                </div>
       
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <GiSharkFin size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={maldive} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Maldive</h2>
                    <span className="date"> 2022 </span>
                    <p>
                      Due anni senza viaggiare? Colpa del Covid. <br />
                      Ma non vi preoccupate abbiamo recuperato alla grande:
                      Maldive unica nel suo genere, se non si scende ad almeno
                      30 metri non puoi godere a pieno della sua magnificenza.
                      Circondati da pesci palla, coralli di ogni forma colore e
                      dimensione, banchi di squali pinna nera ti accompagnano in
                      tranquille e trasperenti acque marine. Avete presente le
                      cartoline? Ecco le Maldive sono realmente così!{" "}
                    </p>
                  </div>
                </div>
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <img src={eiffelTower} style={{width:35}}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={story5} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Il primo Si!</h2>
                    <span className="date"> 2022 </span>
                    <p>
                      Ma una data fondamentale che tutti voi dovete conoscere é
                      quella del 3 ottobre 2022, che con sguardo alla Tour
                      Eiffel e con la piccola Cocó come protagonista ho ricevuto
                      la più romantica ed emozionante proposta di matrimonio che
                      ogni fanciulla possa sognare … e quindi come poter dir di
                      no!?
                      <br />E così un berlocco al dito e tante confuse idee per
                      la testa abbaimo iniziato ad organizzare il nostro grande
                      sogno: potersi presentare come marito e moglie.
                    </p>
                  </div>
                </div>
                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <GiElephant size={24} color={'#9bb7d4'}/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={thailandia2} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Thailandia</h2>
                    <span className="date"> 2023 </span>
                    <p>
                      E infine di nuovo Thailandia probabilmente qua ci abbiamo
                      lasciamo un pezzo di cuore, dove la vita scorre al
                      sinistra e se spendi più di 5 euro per mangiare e come se
                      avessi cenato da Cracco. <br/>Il giusto modo per affrontare l'ansia da matrimonio!
                    </p>
                  </div>
                </div>

                <div className="item">
                <div className="story-icon" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                   <GiRing size={24} color={'#9bb7d4'} fill="#9bb7d4"/>
                  </div>
                  <div className="story-content">
                    <div className="image-wrap">
                      <div className="single-image">
                        <div>
                          <img src={gameover} alt="" />
                        </div>
                      </div>
                    </div>
                    <h2>Il nostro Si!</h2>
                    <span className="date"> 21 Luglio 2023 </span>
                    <p>
                    Speranzosi di avervi convinto ad accompagnarci in questo nuovo grande viaggio vi aspettiamo con ansia in un “freschissimo” 21 luglio incorniciato da camicie dal colletto inamidato, cravatte troppo strette e vestiti anti pezza dai colori neutri (niente colori sgargianti!), con deodoranti travel size e salviettine rinfrescanti presenti in ogni pochette ( anche per noi magari ;)!!

                    </p>
                  </div>
                </div>

              </div>
              <div className="story-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
