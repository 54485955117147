import React, { Component } from "react";
import CountDown from "reactjs-countdown";
import "./style.css";

class Saveday extends Component {
  render() {
    return (
      <div className="count-down-area" id="countdown">
        <div className="count-down-sectionparallax">
          <div className="container">
            <div className="count-down-item count-down-item2">
              <div className="row">
                <div className="col-12 col-md-12 clock-area">
                  <p
                    style={{
                      fontSize: "35px",
                      marginBottom: 30,
                      textAlign: "center",
                      color: "#fff",
                      fontFamily: "Great Vibes",
                    }}
                    className="margin-countdown"
                  >
                    Noi abbiamo iniziato il conto alla rovescia. E tu? <br />
                  </p>

                  <div
                    className="count-down-clock"
                    style={{ marginBottom: 20 }}
                  >
                    <CountDown deadline="July 21, 2023" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Saveday;
