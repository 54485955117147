import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import maury from '../../images/groomsmen-bridesmaid/mauri.png'
import coco from '../../images/groomsmen-bridesmaid/coco.png'
import mucca from '../../images/groomsmen-bridesmaid/mucca.png'
import bride4 from '../../images/groomsmen-bridesmaid/4.jpg'
import bride5 from '../../images/groomsmen-bridesmaid/sharon.jpg'
import bride6 from '../../images/groomsmen-bridesmaid/francesca.jpg'
import bride7 from '../../images/groomsmen-bridesmaid/sonia.jpg'
import bride8 from '../../images/groomsmen-bridesmaid/8.jpg'
import bride9 from '../../images/groomsmen-bridesmaid/9.jpg'
import bride10 from '../../images/groomsmen-bridesmaid/10.jpg'

import './style.css'

const People = (props) => {
    const [activeTab, setActiveTab] = useState('1');

        const toggle = tab => {
          if(activeTab !== tab) setActiveTab(tab);
        }
    return(

        <div id="people" className="person-area section-padding pb-70">
            <div className="container">
                <Sectiontitle section={'Special Guest'}/>
                <div className="">
                   
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                        <div className="Groomsman-wrap">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="person-wrap">
                                            <div className="person-img">
                                                <img src={maury} alt=""/>
                                             
                                            </div>
                                            <div className="person-content">
                                                <h3>Maurizio</h3>
                                                <span>Celebrante</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="person-wrap">
                                            <div className="person-img">
                                                <img src={coco} alt=""/>
                                             
                                            </div>
                                            <div className="person-content">
                                                <h3>Caterina detta Cocò</h3>
                                                <span>Paggetta</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="person-wrap">
                                            <div className="person-img">
                                                <img src={mucca} alt=""/>
                                             
                                            </div>
                                            <div className="person-content">
                                                <h3>Federica</h3>
                                                <span>Aiuto paggetta</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                        <div className="Ceremony-wrap">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="person-wrap">
                                            <div className="person-img">
                                                <img src={bride5} alt=""/>
                                              {/* */}
                                            </div>
                                            <div className="person-content">
                                                <h3>Sharon</h3>
                                                <span>Testimone</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="person-wrap">
                                            <div className="person-img">
                                                <img src={bride6} alt=""/>
                                              {/* */}
                                            </div>
                                            <div className="person-content">
                                                <h3>Francesca</h3>
                                                <span>Testimone</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="person-wrap">
                                            <div className="person-img">
                                                <img src={bride7} alt=""/>
                                               {/*  <div className="social-list">
                                                    <ul>
                                                        <li><Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                                                        <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                                        <li><Link to="/"><i className="fa fa-skype"></i></Link></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <div className="person-content">
                                                <h3>Sonia</h3>
                                                <span>Migliore amica</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
        
    )
}

export default People;