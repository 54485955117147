import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gift1 from '../../images/gift/2.png'
import gift2 from '../../images/gift/3.png'
import gift3 from '../../images/gift/4.png'
import gift4 from '../../images/gift/2.png'
import './style.css'


class Gift extends Component {
    render() {
      var settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:1500,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]
      };
      return (
          <div className="gift-area">
              <div className="container">
                    <div className="col-12" style={{marginBottom:80}}>
                      <div className="section-title text-center">
                          <h2>Aiutaci a realizzare il nostro sogno!</h2>
                          <p>A questo punto avrete capito che per noi viaggiare e niente di più stimolante, per adesso di ore di volo ne abbiamo fatte solamente 14, per cui con il vostro contributo potremmo superare ogni record sparandoci una 40 di ore di volo.

Odiando le agenzie di viaggio abbiamo istituito in fondo per realizzare il nostro sogno futuro: la Polinesia Francese!<br/> Contribuisci ad affrontare il nostro mese di viaggio (vi inonderemo di foto).
<br/>IBAN: IT83W0344216000000026224968</p>
                      </div>
                  </div>
          
              </div>
          </div>
      );
    }
  }

export default Gift;